<template>
  <div class="app-page-toolbar">
    <el-button
      v-if="hasPermissionToCreate"
      icon="el-icon-fa-plus"
      type="primary"
      @click="doCreatNew"
    >
      <app-i18n code="common.newProjectBtn"></app-i18n>
    </el-button>

    <el-tooltip
      :content="destroyButtonTooltip"
      :disabled="!destroyButtonTooltip"
      v-if="hasPermissionToDestroy"
    >
      <span>
        <el-button
          :disabled="destroyButtonDisabled"
          @click="doDestroyAllSelected"
          icon="el-icon-fa-trash"
          type="primary"
        >
          <app-i18n code="common.destroy"></app-i18n>
        </el-button>
      </span>
    </el-tooltip>

    <router-link
      :to="{ path: '/audit-logs', query: { entityNames: 'project' } }"
      v-if="hasPermissionToAuditLogs"
    >
      <el-button icon="el-icon-fa-history">
        <app-i18n code="auditLog.menu"></app-i18n>
      </el-button>
    </router-link>
  </div>
</template>

<script>
import { AuditLogPermissions } from '@/modules/audit-log/audit-log-permissions';
import { mapGetters, mapActions } from 'vuex';
import { ProjectPermissions } from '@/modules/project/project-permissions';
import { i18n } from '@/i18n';

export default {
  name: 'app-project-list-toolbar',

  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser',
      hasRows: 'project/list/hasRows',
      loading: 'project/list/loading',
      selectedRows: 'project/list/selectedRows',
      destroyLoading: 'project/destroy/loading',
    }),

    hasPermissionToAuditLogs() {
      return new AuditLogPermissions(this.currentUser).read;
    },

    hasPermissionToCreate() {
      return new ProjectPermissions(this.currentUser).create;
    },

    hasPermissionToEdit() {
      return new ProjectPermissions(this.currentUser).edit;
    },

    hasPermissionToDestroy() {
      return new ProjectPermissions(this.currentUser).destroy;
    },

    destroyButtonDisabled() {
      return (
        !this.selectedRows.length ||
        this.loading ||
        this.destroyLoading
      );
    },

    destroyButtonTooltip() {
      if (!this.selectedRows.length || this.loading) {
        return i18n('common.mustSelectARow');
      }

      return null;
    },
  },

  methods: {
    ...mapActions({
      doDestroyAll: 'project/destroy/doDestroyAll',
      doCreate: 'project/form/doCreate',
    }),

    async doDestroyAllSelected() {
      try {
        await this.$confirm(
          i18n('common.areYouSure'),
          i18n('common.confirm'),
          {
            confirmButtonText: i18n('common.yes'),
            cancelButtonText: i18n('common.no'),
            type: 'warning',
          },
        );

        return this.doDestroyAll(
          this.selectedRows.map((item) => item.id),
        );
      } catch (error) {
        // no
      }
    },

    async doCreatNew() {
      try {
        await this.$confirm(
          i18n('common.areYouSure'),
          i18n('common.confirm'),
          {
            confirmButtonText: i18n('common.yes'),
            cancelButtonText: i18n('common.no'),
            type: 'warning',
          },
        );

        await this.doCreate();
      } catch (error) {
        //no
      }
    },
  },
};
</script>

<style>
</style>
