import { i18n } from '@/i18n';
import IdField from '@/shared/fields/id-field';
import DateTimeRangeField from '@/shared/fields/date-time-range-field';
import DateTimeField from '@/shared/fields/date-time-field';
import { GenericModel } from '@/shared/model/generic-model';
import StringField from '@/shared/fields/string-field';
import EnumeratorField from '@/shared/fields/enumerator-field';
import { ProjectCreateField } from '@/modules/project-create/project-create-field';
import { ProjectReleaseField } from '@/modules/project-release/project-release-field';
import { ProjectFundingField } from '@/modules/project-funding/project-funding-field';
import { ProjectGanttField } from '@/modules/project-gantt/project-gantt-field';
import { ProjectImplementationField } from '@/modules/project-implementation/project-implementation-field';
import { ProjectReportField } from '@/modules/project-report/project-report-field';
import { FacultyField } from '@/modules/faculty/faculty-field';
import { UserField } from '@/modules/auth/user-field';

function label(name) {
  return i18n(`entities.project.fields.${name}`);
}

function enumeratorLabel(name, value) {
  return i18n(`entities.project.enumerators.${name}.${value}`);
}

const fields = {
  id: new IdField('id', label('id')),
  projectID: new StringField('projectID', label('projectID'), {}),
  title: new StringField('title', label('title'), {}),
  faculty: FacultyField.relationToMany('faculty', label('faculty'), {}),
  facultyFilter: FacultyField.relationToOne('faculty', label('faculty'), {}),
  projectManager: UserField.relationToMany('projectManager', label('projectManager'), {}),
  accountingProjectNo: new StringField('accountingProjectNo', label('accountingProjectNo'), {}),
  status: new EnumeratorField('status', label('status'), [
    { id: 'CREATED/PROJECT_RELEASE_OPEN', label: enumeratorLabel('status', 'CREATED/PROJECT_RELEASE_OPEN') },
    { id: 'CREATED/REQUESTED_FOR_RELEASE', label: enumeratorLabel('status', 'CREATED/REQUESTED_FOR_RELEASE') },
    { id: 'REJECTED', label: enumeratorLabel('status', 'REJECTED') },
    { id: 'RUNNING', label: enumeratorLabel('status', 'RUNNING') },
    { id: 'CREATED/REVISION_REQUESTED', label: enumeratorLabel('status', 'CREATED/REVISION_REQUESTED') },
    { id: 'REJECTED', label: enumeratorLabel('status', 'REJECTED') },
    { id: 'RUNNING/FUNDING_APPROVED', label: enumeratorLabel('status', 'RUNNING/FUNDING_APPROVED') },
    { id: 'RUNNING/FUNDING_REJECTED', label: enumeratorLabel('status', 'RUNNING/FUNDING_REJECTED') },
    { id: 'RUNNING/WITHOUT_FUNDING', label: enumeratorLabel('status', 'RUNNING/WITHOUT_FUNDING') },
    { id: 'CLOSED', label: enumeratorLabel('status', 'CLOSED') },
    { id: 'COMPLETED', label: enumeratorLabel('status', 'COMPLETED') },
  ], {}),
  step: new EnumeratorField('step', label('step'), [
    { id: 'create', label: 'create' },
    { id: 'release', label: 'release' },
    { id: 'funding', label: 'funding' },
    { id: 'gantt', label: 'gantt' },
    { id: 'implementation', label: 'implementation' },
    { id: 'reports', label: 'reports' },
    { id: 'completion', label: 'completion' },
  ], {}),

  projectCreate: ProjectCreateField.relationToOne('projectCreate', label('projectCreate'), {}),
  projectRelease: ProjectReleaseField.relationToOne('projectRelease', label('projectRelease'), {}),
  projectFunding: ProjectFundingField.relationToOne('projectFunding', label('projectFunding'), {}),
  projectGantt: ProjectGanttField.relationToOne('projectGantt', label('projectGantt'), {}),
  projectImplementation: ProjectImplementationField.relationToOne('projectImplementation', label('projectImplementation'), {}),
  projectReport: ProjectReportField.relationToOne('projectReport', label('projectReport'), {}),

  createdAt: new DateTimeField(
    'createdAt',
    label('createdAt'),
  ),
  updatedAt: new DateTimeField(
    'updatedAt',
    label('updatedAt'),
  ),
  createdAtRange: new DateTimeRangeField(
    'createdAtRange',
    label('createdAtRange'),
  ),

};

export class ProjectModel extends GenericModel {
  static get fields() {
    return fields;
  }
}
