<template>
  <el-collapse value='Filter' accordion>
    <el-collapse-item title="Filter" name="Filter">
      <el-form
        :label-position="labelPosition"
        :label-width="labelWidthFilter"
        :model="model"
        :rules="rules"
        @submit.native.prevent="doFilter"
        class="filter"
        ref="form"
      >
        <el-row>
          <el-col :lg="6" :md="8" :sm="12">
            <el-form-item
              :label="fields.projectID.label"
              :prop="fields.projectID.name"
            >
              <el-input v-model="model[fields.projectID.name]" />
            </el-form-item>
          </el-col>

          <el-col :lg="6" :md="8" :sm="12">
            <el-form-item
              :label="fields.title.label"
              :prop="fields.title.name"
            >
              <el-input v-model="model[fields.title.name]" />
            </el-form-item>
          </el-col>

          <el-col :lg="6" :md="8" :sm="12">
            <el-form-item
              :label="fields.faculty.label"
              :prop="fields.faculty.name"
            >
              <app-autocomplete-many-input
                :fetchFn="fields.faculty.fetchFn"
                v-model="model[fields.faculty.name]"
              ></app-autocomplete-many-input>
            </el-form-item>
          </el-col>

          <el-col :lg="8" :md="10" :sm="14">
            <el-form-item
              :label="fields.projectManager.label"
              :prop="fields.projectManager.name"
            >
              <app-autocomplete-many-input
                :fetchFn="fields.projectManager.fetchFn"
                v-model="model[fields.projectManager.name]"
              ></app-autocomplete-many-input>
            </el-form-item>
          </el-col>

          <el-col :lg="6" :md="8" :sm="12">
            <el-form-item
              :label="fields.accountingProjectNo.label"
              :prop="fields.accountingProjectNo.name"
            >
              <el-input
                v-model="model[fields.accountingProjectNo.name]"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <div class="filter-buttons">
            <el-button
              :disabled="loading"
              @click="doFilter"
              icon="el-icon-fa-search"
              type="primary"
            >
              <app-i18n code="common.search"></app-i18n>
            </el-button>

            <el-button
              :disabled="loading"
              @click="doResetFilter"
              icon="el-icon-fa-undo"
            >
              <app-i18n code="common.reset"></app-i18n>
            </el-button>
          </div>
        </el-row>
      </el-form>
    </el-collapse-item>
  </el-collapse>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { FilterSchema } from '@/shared/form/filter-schema';
import { ProjectModel } from '@/modules/project/project-model';

const { fields } = ProjectModel;

const filterSchema = new FilterSchema([
  fields.id,
  fields.projectID,
  fields.title,
  fields.faculty,
  fields.projectManager,
  fields.accountingProjectNo,
]);

export default {
  name: 'app-project-list-filter',

  data() {
    return {
      rules: filterSchema.rules(),
      model: {},
    };
  },

  computed: {
    ...mapGetters({
      labelPosition: 'layout/labelPosition',
      labelWidthFilter: 'layout/labelWidthFilter',
      loading: 'project/list/loading',
      filter: 'project/list/filter',
    }),

    fields() {
      return fields;
    },
  },

  async mounted() {
    this.model = filterSchema.initialValues(
      this.filter,
      this.$route.query,
    );

    return this.doFilter();
  },

  methods: {
    ...mapActions({
      doReset: 'project/list/doReset',
      doFetch: 'project/list/doFetch',
    }),

    async doResetFilter() {
      this.model = filterSchema.initialValues();
      this.$refs.form.clearValidate();
      return this.doReset();
    },

    async doFilter() {
      try {
        await this.$refs.form.validate();
        this.$refs.form.clearValidate();
      } catch (error) {
        return;
      }

      const filter = filterSchema.cast(this.model);
      return this.doFetch({
        filter,
      });
    },
  },
};
</script>

<style>
</style>
