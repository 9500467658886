<template>
  <div>
    <el-table
      :border="!inHomePage"
      :data="rows"
      @sort-change="doChangeSort"
      :fit="true"
      ref="table"
      row-key="id"
      v-loading="loading"
      tooltip-effect="light"
      :size="inHomePage ? 'mini' : undefined"
    >
      <el-table-column
        type="selection"
        width="55"
        v-if="!inHomePage"
      ></el-table-column>

      <el-table-column
        :label="fields.projectID.label"
        :prop="fields.projectID.name"
        sortable="custom"
      >
        <template slot-scope="scope">{{
          presenter(scope.row, 'projectID')
        }}</template>
      </el-table-column>

      <el-table-column
        :label="fields.title.label"
        :prop="fields.title.name"
        :show-overflow-tooltip="true"
        v-if="!inHomePage"
      >
        <template slot-scope="scope">
          {{ presenter(scope.row, 'title') }}</template
        >
      </el-table-column>

      <el-table-column
        :label="fields.faculty.label"
        :prop="fields.faculty.name"
        :min-width="40"
        class="col"
      >
        <template slot-scope="scope" class="col">
          <app-list-item-relation-to-many
            :label="fields.faculty.label"
            :readPermission="fields.faculty.readPermission"
            :navigatePermission="fields.faculty.navigatePermission"
            :url="fields.faculty.viewUrl"
            :value="presenter(scope.row, 'faculty')"
          ></app-list-item-relation-to-many>
        </template>
      </el-table-column>

      <el-table-column
        :label="fields.projectManager.label"
        :prop="fields.projectManager.name"
        class="col"
        v-if="!inHomePage"
      >
        <template slot-scope="scope">
          <app-list-item-relation-to-many
            :label="fields.projectManager.label"
            :readPermission="fields.projectManager.readPermission"
            :navigatePermission="
              fields.projectManager.navigatePermission
            "
            :url="fields.projectManager.viewUrl"
            :value="presenter(scope.row, 'projectManager')"
          ></app-list-item-relation-to-many>
        </template>
      </el-table-column>

      <el-table-column
        :label="fields.accountingProjectNo.label"
        :prop="fields.accountingProjectNo.name"
        :show-overflow-tooltip="true"
        v-if="!inHomePage"
      >
        <template slot-scope="scope">
          {{ presenter(scope.row, 'accountingProjectNo') }}</template
        >
      </el-table-column>

      <el-table-column
        :label="fields.status.label"
        :prop="fields.status.name"
        sortable="custom"
      >
        <template v-slot="scope">
          <el-tag
            :type="getStatusTagType(scope.row[fields.status.name])"
            v-if="isStatusNull(scope.row[fields.status.name])"
            >{{ presenter(scope.row, 'status') }}</el-tag
          >
        </template>
      </el-table-column>

      <el-table-column
        :fixed="isMobile ? undefined : 'right'"
        align="center"        
      >
        <template slot-scope="scope">
          <div class="table-actions">
            <router-link :to="`/project/${scope.row.id}`">
              <el-button type="text">
                <app-i18n code="common.view"></app-i18n>
              </el-button>
            </router-link>

            <router-link
              :to="`/project/${scope.row.id}/edit`"
              v-if="hasPermissionToEdit"
            >
              <el-button type="text">
                <app-i18n code="common.edit"></app-i18n>
              </el-button>
            </router-link>

            <el-button
              :disabled="destroyLoading"
              @click="doDestroyWithConfirm(scope.row.id)"
              type="text"
              v-if="hasPermissionToDestroy && !inHomePage"
            >
              <app-i18n code="common.destroy"></app-i18n>
            </el-button>
          </div>
        </template>
      </el-table-column>
    </el-table>

    <div class="el-pagination-wrapper" v-if="!inHomePage">
      <el-pagination
        :current-page="pagination.currentPage || 1"
        :disabled="loading"
        :layout="paginationLayout"
        :total="count"
        @current-change="doChangePaginationCurrentPage"
        @size-change="doChangePaginationPageSize"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
import { ProjectModel } from '@/modules/project/project-model';
import { mapGetters, mapActions } from 'vuex';
import { ProjectPermissions } from '@/modules/project/project-permissions';
import { i18n } from '@/i18n';

const { fields } = ProjectModel;

export default {
  name: 'app-project-list-table',

  props: ['inHomePage'],

  mounted() {
    this.doMountTable(this.$refs.table);
  },

  computed: {
    ...mapGetters({
      rows: 'project/list/rows',
      count: 'project/list/count',
      loading: 'project/list/loading',
      dataFetched: 'project/list/dataFetched',
      pagination: 'project/list/pagination',
      isMobile: 'layout/isMobile',
      currentUser: 'auth/currentUser',
      destroyLoading: 'project/destroy/loading',
      paginationLayout: 'layout/paginationLayout',
    }),

    hasPermissionToEdit() {
      return new ProjectPermissions(this.currentUser).edit;
    },

    hasPermissionToDestroy() {
      return new ProjectPermissions(this.currentUser).destroy;
    },

    fields() {
      return fields;
    },
  },

  methods: {
    ...mapActions({
      doChangeSort: 'project/list/doChangeSort',
      doChangePaginationCurrentPage:
        'project/list/doChangePaginationCurrentPage',
      doChangePaginationPageSize:
        'project/list/doChangePaginationPageSize',
      doMountTable: 'project/list/doMountTable',
      doDestroy: 'project/destroy/doDestroy',
    }),

    presenter(row, fieldName) {
      return ProjectModel.presenter(row, fieldName);
    },

    isStatusNull(status) {
      return status === null ? false : true;
    },

    getStatusTagType(status) {
      if (status === 'REJECTED') {
        return 'danger';
      }
      if (status === 'CREATED/REQUESTED_FOR_RELEASE') {
        return 'primary';
      }
      if (
        status === 'CREATED/PROJECT_RELEASE_OPEN' ||
        status === 'CLOSED' ||
        status === 'COMPLETED'
      ) {
        return 'info';
      }
      if (status === 'RUNNING') {
        return 'success';
      }
      if (status === 'CREATED/REVISION_REQUESTED') {
        return 'warning';
      }
    },

    async doDestroyWithConfirm(id) {
      try {
        await this.$confirm(
          i18n('common.areYouSure'),
          i18n('common.confirm'),
          {
            confirmButtonText: i18n('common.yes'),
            cancelButtonText: i18n('common.no'),
            type: 'warning',
          },
        );

        return this.doDestroy(id);
      } catch (error) {
        // no
      }
    },
  },
};
</script>

<style scoped>
/* 
.el-table .cell div>* {
  word-break: break-word;
  box-sizing: border-box;
  border-color: transparent;
  margin: 2px 0 2px 6px;
  background-color: #f0f2f5;
} */
</style>